import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import '@fontsource/cairo';
import './css/common.scss';
import ConfigData from './includes/config.json';
import Home from './pages/Home';
import ThankyouPage from './pages/ThankyouPage';
import Policy from './pages/Policy';
import Header from './components/Header';
import axios from 'axios';

class App extends Component {
	componentDidMount() {
		this.checkBlackList();
	}

	async checkBlackList() {
		var Buffer = require('buffer/').Buffer;
		const username = ConfigData.Authorization.username;
		const password = ConfigData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
		const response = await axios({
			method: 'POST',
			url: ConfigData.server_URI + '/common/is-in-blacklist',
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		if (response.data === 'banned') {
			window.location.replace('https://google.com');
		}
	}

	updateEnvironmentVariables() {
		// ConfigData.owner_id = process.env.REACT_APP_OWNER_ID;
	}

	render() {
		this.updateEnvironmentVariables();
		return (
			<div className="App">
				<Header />
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/policy" component={Policy} />
					<Route exact path="/thankyou" component={ThankyouPage} />
					<Redirect to="/" />
				</Switch>
			</div>
		);
	}
}

export default App;
