import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './styles.scss';

class ThankyouPage extends Component {

	render() {
		return (
			<div className="thankyou-content">
				<p className='thank-you-title'>شكرا لك</p>
				<i class="fa-solid fa-check"></i>
				<p className='thank-you-content'>تم ارسال طلبك بنجاح وسيتم التواصل معك باقرب وقت ممكن</p>
				<NavLink className="back-to-home" to="/">الصفحة الرئيسية</NavLink>
			</div>
		);
	}
}

export default ThankyouPage;
